import { flexRender } from "@tanstack/react-table";
import React from "react";
import { Checkbox } from "../../../components/ui/checkbox";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";

interface DataTableProps {
  data: any[];
  setSelectedRows: (rows: any) => void;
  selectedRows: any;
  table: any;
}

const DataTable: React.FC<DataTableProps> = ({ data, setSelectedRows, selectedRows, table }) => {
  return (
    <div>
      <div style={{maxHeight: '60vh'}}>
        <Table>
          <TableHeader className="!bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead className="py-0.5 text-center"></TableHead>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className="py-0.5 text-center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {table.getRowModel().rows.map((row, index) => {
              const rowClassName = index % 2 === 0 ? "bg-blue-50" : "bg-white";
              return (
                <TableRow className={rowClassName} key={row.id}>
                  {/* Row Selection */}
                  <TableCell className="!bg-transparent py-0.5 max-md:text-xs [&:has([role=checkbox])]:pr-4">
                    <Checkbox
                      checked={row.getIsSelected()} // Reflects if the row is selected
                      onCheckedChange={(value) => row.toggleSelected(!!value)} // Toggles row selection
                      aria-label="Select row"
                    />
                  </TableCell>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className="!bg-transparent py-0.5 max-md:text-xs" key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default DataTable;
